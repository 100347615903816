var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"background":"#fff","border-radius":"6px","padding":"0 10px 10px"}},[_c('div',{staticClass:"boxTit"},[_vm._m(0),_c('div',[_c('el-button',{staticStyle:{"color":"#999","font-size":"16px"},attrs:{"type":"text","icon":"el-icon-s-operation"}},[_vm._v("设置")])],1)]),_c('div',{staticClass:"imgList"},[_c('div',{staticClass:"img",on:{"click":() => {
            this.$router.push('/courseStart/startClassStatistics')
          }}},[_c('img',{attrs:{"src":require("@/assets/homepage/icon_shortcut_arrange.png")}})]),_c('div',{staticClass:"img",on:{"click":() => {
            this.$router.push('/materialManage/materialAccount')
          }}},[_c('img',{attrs:{"src":require("@/assets/homepage/icon_shortcut_bill.png")}})]),_c('div',{staticClass:"img",on:{"click":() => {
            this.$router.push('/materialManage/inStockManage')
          }}},[_c('img',{attrs:{"src":require("@/assets/homepage/icon_shortcut_depot.png")}})]),_c('div',{staticClass:"img",on:{"click":() => {
            this.$router.push('/materialManage/dangerousMaterial')
          }}},[_c('img',{attrs:{"src":require("@/assets/homepage/icon_shortcut_hazardous.png")}})]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)])]),_c('div',{staticStyle:{"display":"flex","margin-top":"20px"}},[_vm._m(5),_c('div',{staticStyle:{"width":"calc(50% - 10px)","margin-left":"10px","background":"#fff","border-radius":"6px","padding":"10px"}},[_vm._m(6),_c('div',{staticClass:"taskBoxList"},[_c('div',{staticClass:"taskNumBox"},[_c('div',{staticStyle:{"font-size":"30px"}},[_vm._v("8")]),_c('div',{staticStyle:{"color":"#98aac0","margin":"10px 0"}},[_vm._v("待办任务")]),_c('el-button',{staticStyle:{"display":"block","margin":"0 auto"},attrs:{"icon":"el-icon-arrow-right","circle":"","size":"mini"}})],1),_vm._m(7)])])]),_c('div',{staticStyle:{"background":"#fff","margin-top":"20px","border-radius":"6px","padding":"0 10px 10px"}},[_c('div',{staticClass:"boxTit"},[_vm._m(8),_c('div',[_c('el-button',{staticStyle:{"color":"#1886fe","font-size":"16px"},attrs:{"type":"text","icon":"el-icon-refresh-right"},on:{"click":_vm.changTimestamp}},[_vm._v("回到本周")])],1)]),_c('curriculumCom',{ref:"curriculumCom"})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"boxTitImgText"},[_c('img',{attrs:{"src":require("@/assets/homepage/icon_home_shortcut.png")}}),_c('div',[_vm._v("快捷入口")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/homepage/icon_shortcut_loss.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/homepage/icon_shortcut_patrol.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/homepage/icon_shortcut_task.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/homepage/icon_shortcut_teach.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"calc(50% - 10px)","margin-right":"10px","background":"#fff","border-radius":"6px","padding":"10px"}},[_c('div',{staticClass:"boxTit"},[_c('div',{staticClass:"boxTitImgText"},[_c('img',{attrs:{"src":require("@/assets/homepage/icon_home_message.png")}}),_c('div',[_vm._v("消息通知")])]),_c('div',[_c('div',{staticStyle:{"cursor":"pointer","color":"#999999"}},[_vm._v("查看更多 >")])])]),_c('div',{staticClass:"infoBoxList"},[_c('div',{staticClass:"infoBoxLi dotRed"},[_c('div',{staticClass:"infoBoxLiLeft"},[_c('div',{staticClass:"infoTop ellipsis"},[_vm._v(" [系统公告]关于做好高等院校2024年危化品盘点工作通知 ")]),_c('div',{staticClass:"infoName"},[_vm._v("广州市教育局备份")])]),_c('div',{staticClass:"infoBoxLiRight"},[_vm._v("2024-06-05 11:28:30")])]),_c('div',{staticClass:"infoBoxLi dotRed"},[_c('div',{staticClass:"infoBoxLiLeft"},[_c('div',{staticClass:"infoTop ellipsis"},[_vm._v(" [系统公告]2024年第一学期教学计划上报通知 ")]),_c('div',{staticClass:"infoName"},[_vm._v("广州市教育局备份")])]),_c('div',{staticClass:"infoBoxLiRight"},[_vm._v("2024-06-03 10:30:00")])]),_c('div',{staticClass:"infoBoxLi dot"},[_c('div',{staticClass:"infoBoxLiLeft"},[_c('div',{staticClass:"infoTop ellipsis"},[_vm._v(" [系统公告]平台维护公告_20240530 ")]),_c('div',{staticClass:"infoName"},[_vm._v("广州市教育局备份")])]),_c('div',{staticClass:"infoBoxLiRight"},[_vm._v("2024-05-30 17:00:30")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"boxTit"},[_c('div',{staticClass:"boxTitImgText"},[_c('img',{attrs:{"src":require("@/assets/homepage/icon_home_task.png")}}),_c('div',[_vm._v("待办任务")])]),_c('div',[_c('div',{staticStyle:{"cursor":"pointer","color":"#999999"}},[_vm._v("查看更多 >")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"taskNumBoxList"},[_c('div',{staticClass:"taskNumBoxLi"},[_c('img',{attrs:{"src":require("@/assets/homepage/icon_home_mission.png")}}),_c('div',{staticClass:"taskInfoBox"},[_c('div',{staticClass:"taskInfoBoxName ellipsis"},[_vm._v(" 初一6班[制作并观察蔬菜叶表皮细胞临时装片] ")]),_c('div',{staticClass:"taskInfoBoxTime ellipsis"},[_vm._v(" 实验教学计划｜2024-03-4 至 2024-06-28 ")])]),_c('div',{staticClass:"taskTimeBox taskRed"},[_c('i',{staticClass:"el-icon-time"}),_vm._v("3天 ")])]),_c('div',{staticClass:"taskNumBoxLi"},[_c('img',{attrs:{"src":require("@/assets/homepage/icon_home_mission.png")}}),_c('div',{staticClass:"taskInfoBox"},[_c('div',{staticClass:"taskInfoBoxName ellipsis"},[_vm._v(" 初一6班[探究凸透镜成像规律] ")]),_c('div',{staticClass:"taskInfoBoxTime ellipsis"},[_vm._v(" 实验教学计划｜2024-03-4 至 2024-06-28 ")])]),_c('div',{staticClass:"taskTimeBox taskBlue"},[_c('i',{staticClass:"el-icon-time"}),_vm._v("13天 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"boxTitImgText"},[_c('img',{attrs:{"src":require("@/assets/homepage/icon_home_shortcut.png")}}),_c('div',[_vm._v("课程表")])])
}]

export { render, staticRenderFns }