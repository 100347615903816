<template>
  <div>
    <!-- 快捷入口 -->
    <div style="background: #fff; border-radius: 6px; padding: 0 10px 10px">
      <div class="boxTit">
        <div class="boxTitImgText">
          <img src="@/assets/homepage/icon_home_shortcut.png" />
          <div>快捷入口</div>
        </div>
        <div>
          <el-button
            style="color: #999; font-size: 16px"
            type="text"
            icon="el-icon-s-operation"
            >设置</el-button
          >
        </div>
      </div>
      <div class="imgList">
        <div
          class="img"
          @click="
            () => {
              this.$router.push('/courseStart/startClassStatistics')
            }
          "
        >
          <img src="@/assets/homepage/icon_shortcut_arrange.png" />
        </div>
        <div
          class="img"
          @click="
            () => {
              this.$router.push('/materialManage/materialAccount')
            }
          "
        >
          <img src="@/assets/homepage/icon_shortcut_bill.png" />
        </div>
        <div
          class="img"
          @click="
            () => {
              this.$router.push('/materialManage/inStockManage')
            }
          "
        >
          <img src="@/assets/homepage/icon_shortcut_depot.png" />
        </div>
        <div
          class="img"
          @click="
            () => {
              this.$router.push('/materialManage/dangerousMaterial')
            }
          "
        >
          <img src="@/assets/homepage/icon_shortcut_hazardous.png" />
        </div>
        <div class="img">
          <img src="@/assets/homepage/icon_shortcut_loss.png" />
        </div>
        <div class="img">
          <img src="@/assets/homepage/icon_shortcut_patrol.png" />
        </div>
        <div class="img">
          <img src="@/assets/homepage/icon_shortcut_task.png" />
        </div>
        <div class="img">
          <img src="@/assets/homepage/icon_shortcut_teach.png" />
        </div>
      </div>
    </div>

    <!-- 消息 -->
    <div style="display: flex; margin-top: 20px">
      <!-- 消息通知 -->
      <div
        style="
          width: calc(50% - 10px);
          margin-right: 10px;
          background: #fff;
          border-radius: 6px;
          padding: 10px;
        "
      >
        <div class="boxTit">
          <div class="boxTitImgText">
            <img src="@/assets/homepage/icon_home_message.png" />
            <div>消息通知</div>
          </div>
          <div>
            <div style="cursor: pointer; color: #999999">查看更多 ></div>
          </div>
        </div>
        <div class="infoBoxList">
          <div class="infoBoxLi dotRed">
            <div class="infoBoxLiLeft">
              <div class="infoTop ellipsis">
                [系统公告]关于做好高等院校2024年危化品盘点工作通知
              </div>
              <div class="infoName">广州市教育局备份</div>
            </div>
            <div class="infoBoxLiRight">2024-06-05 11:28:30</div>
          </div>
          <div class="infoBoxLi dotRed">
            <div class="infoBoxLiLeft">
              <div class="infoTop ellipsis">
                [系统公告]2024年第一学期教学计划上报通知
              </div>
              <div class="infoName">广州市教育局备份</div>
            </div>
            <div class="infoBoxLiRight">2024-06-03 10:30:00</div>
          </div>
          <div class="infoBoxLi dot">
            <div class="infoBoxLiLeft">
              <div class="infoTop ellipsis">
                [系统公告]平台维护公告_20240530
              </div>
              <div class="infoName">广州市教育局备份</div>
            </div>
            <div class="infoBoxLiRight">2024-05-30 17:00:30</div>
          </div>
        </div>
      </div>
      <!-- 待办任务 -->
      <div
        style="
          width: calc(50% - 10px);
          margin-left: 10px;
          background: #fff;
          border-radius: 6px;
          padding: 10px;
        "
      >
        <div class="boxTit">
          <div class="boxTitImgText">
            <img src="@/assets/homepage/icon_home_task.png" />
            <div>待办任务</div>
          </div>
          <div>
            <div style="cursor: pointer; color: #999999">查看更多 ></div>
          </div>
        </div>
        <div class="taskBoxList">
          <div class="taskNumBox">
            <div style="font-size: 30px">8</div>
            <div style="color: #98aac0; margin: 10px 0">待办任务</div>
            <el-button
              style="display: block; margin: 0 auto"
              icon="el-icon-arrow-right"
              circle
              size="mini"
            ></el-button>
          </div>
          <div class="taskNumBoxList">
            <div class="taskNumBoxLi">
              <img src="@/assets/homepage/icon_home_mission.png" />
              <div class="taskInfoBox">
                <div class="taskInfoBoxName ellipsis">
                  初一6班[制作并观察蔬菜叶表皮细胞临时装片]
                </div>
                <div class="taskInfoBoxTime ellipsis">
                  实验教学计划｜2024-03-4 至 2024-06-28
                </div>
              </div>
              <div class="taskTimeBox taskRed">
                <i class="el-icon-time"></i>3天
              </div>
            </div>
            <div class="taskNumBoxLi">
              <img src="@/assets/homepage/icon_home_mission.png" />
              <div class="taskInfoBox">
                <div class="taskInfoBoxName ellipsis">
                  初一6班[探究凸透镜成像规律]
                </div>
                <div class="taskInfoBoxTime ellipsis">
                  实验教学计划｜2024-03-4 至 2024-06-28
                </div>
              </div>
              <div class="taskTimeBox taskBlue">
                <i class="el-icon-time"></i>13天
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 课程表 -->
    <div
      style="
        background: #fff;
        margin-top: 20px;
        border-radius: 6px;
        padding: 0 10px 10px;
      "
    >
      <div class="boxTit">
        <div class="boxTitImgText">
          <img src="@/assets/homepage/icon_home_shortcut.png" />
          <div>课程表</div>
        </div>
        <div>
          <el-button
            style="color: #1886fe; font-size: 16px"
            type="text"
            icon="el-icon-refresh-right"
            @click="changTimestamp"
            >回到本周</el-button
          >
        </div>
      </div>
      <curriculumCom ref="curriculumCom" />
    </div>
  </div>
</template>

<script>
import curriculumCom from '@/views/courseStart/courseArrangement/curriculum.vue'
export default {
  name: 'homepage',
  components: {
    curriculumCom,
  },
  data() {
    return {}
  },
  mounted() {
    document.querySelector('body').setAttribute('style', 'background:#F5F6FA')
    this.$refs.curriculumCom.showClose = false
  },
  methods: {
    changTimestamp() {
      this.$refs.curriculumCom.timestamp = new Date().getTime()
      this.$refs.curriculumCom.getClassScheduleFunc({})
    },
  },
  beforeDestroy() {
    document.querySelector('body').removeAttribute('style')
  },
}
</script>

<style scoped lang="scss">
.boxTit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f9f9f9;
  box-sizing: border-box;
}
.imgList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .img {
    cursor: pointer;
    // margin-right: 10px;
    border-radius: 10px;
    width: 184px;
    height: 122px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.boxTitImgText {
  display: flex;
  align-items: center;
  margin: 0;
  img {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }
  div {
    height: 22px;
    line-height: 22px;
  }
}
// 消息通知
.infoBoxList {
  width: 100%;
  box-sizing: border-box;
  height: 180px;
  overflow-y: auto;
  .infoBoxLi {
    height: 60px;
    padding: 10px;
    padding-left: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
      background: #e0e0e0d0;
      border-radius: 6px;
    }
    .infoBoxLiLeft {
      width: calc(100% - 160px);
      .infoTop {
        font-size: 16px;
        line-height: 20px;
      }
      .infoName {
        font-size: 14px;
        color: #999999;
      }
    }
    .infoBoxLiRight {
      width: 160px;
      font-size: 14px;
      color: #999999;
      text-align: right;
    }
  }
  .dotRed {
    position: relative;
    &::after {
      content: ' ';
      height: 7px;
      width: 7px;
      background: #ff001d;
      position: absolute;
      top: calc(50% - 4px);
      left: 4px;
      border-radius: 50%;
    }
  }
  .dot {
    position: relative;
    &::after {
      content: ' ';
      height: 7px;
      width: 7px;
      background: #98aac0;
      position: absolute;
      top: calc(50% - 4px);
      left: 4px;
      border-radius: 50%;
    }
  }
}
// 待办任务
.taskBoxList {
  width: 100%;
  box-sizing: border-box;
  height: 180px;
  display: flex;
  align-content: center;
  .taskNumBox {
    width: 106px;
    background: #e2f4ff;
    padding: 28px 0;
    box-sizing: border-box;
    margin-right: 10px;
    > div {
      margin: 0 auto;
      text-align: center;
    }
  }
  .taskNumBoxList {
    width: calc(100% - 116px);
    overflow-y: auto;
    .taskNumBoxLi {
      height: 80px;
      border: 1px solid #f2f2f2;
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 6px;
      margin-bottom: 10px;
      .taskInfoBox {
        width: calc(100% - 120px);
        height: 40px;
        margin: 0 10px;
        .taskInfoBoxName {
          font-size: 16px;
          font-weight: bold;
          line-height: 20px;
        }
        .taskInfoBoxTime {
          font-size: 14px;
          color: #98aac0;
          line-height: 20px;
        }
      }
      .taskTimeBox {
        height: 24px;
        width: 60px;
        border-radius: 24px;
        border-bottom-right-radius: 0;
        display: flex;
        box-sizing: border-box;
        padding: 0 4px;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        font-size: 14px;
        i {
          font-size: 20px;
        }
      }
      .taskRed {
        background: linear-gradient(to right, #ff4848, #ff6f6f);
      }
      .taskBlue {
        background: linear-gradient(to right, #107cff, #5ba5ff);
      }
    }
  }
}
.curriculumBox {
  width: 100%;
  // 星期
  .weekBox {
    background: #f9f9f9;
    display: flex;
    height: 76px;
    width: 100%;
  }
  .placeholderBox {
    width: 112px;
    margin-right: 18px;
  }
  .weekBoxList {
    width: calc(100% - 130px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .weekBoxLi {
      width: 196px;
      margin: 0;
      padding: 0;
      text-align: center;
      list-style: none;
      li {
        width: 100%;

        text-align: center;
      }
    }
  }
  .classInfo {
    margin-top: 10px;
    display: flex;
    width: 100%;
    .placeholderBox {
      height: 206px;
      background: #f9f9f9;
      box-sizing: border-box;
      padding: 76px 0;
      div {
        width: 100%;
        text-align: center;
      }
    }
    .lessonsList {
      height: 206px;
      width: calc(100% - 130px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        background: #f9f9f9;
        width: 196px;
        height: 206px;
        box-sizing: border-box;
        padding: 34px 18px;
        .lessonsName {
          margin-bottom: 20px;
          width: 100%;
          font-size: 18px;
        }
        .lessonsInfo {
          font-size: 14px;
          color: #586782;
          width: 100%;
          box-sizing: border-box;
          margin: 0;
          vertical-align: middle;
          img {
            width: 14px;
            height: 14px;
            margin-right: 5px;
          }
        }
        .voidBox {
          margin-top: 30px;
          img {
            display: block;
            width: 42px;
            height: 42px;
            margin: 0 auto;
          }
          span {
            display: block;
            margin: 0 auto;
            margin-top: 10px;
            line-height: 14px;
            font-size: 14px;
            color: #dadcdf;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
